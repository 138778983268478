<template>
  <EuiContainer class="es-dashboard">
    <EuiGrid gutters>
      <!-- **TOOLBAR** -->
      <EuiGridRow>
        <EuiGridColumn>
            <EuiHeading :level="1" bold>{{ $t('dashboard.title') }}</EuiHeading>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiHomeCard icon="sign"
                       :title="counters.paraphers"
                       :subtitle="$t('dashboard.counters.paraphers.title')"
                       :button="$t('button.create')"
                       color="purple"
                       @click:homeCard="$router.push({ name: 'signworkflows', params: { tab: 'inbox' } })"
                       @click:action="$router.push({ name: 'paraphers.create.addParameters' })"/>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiHomeCard icon="send"
                       :title="counters.signatories"
                       :subtitle="$t('dashboard.counters.signatories.title')"
                       :button="$t('button.create')"
                       color="pink"
                       @click:homeCard="$router.push({ name: 'signatories.external.index' })"
                       @click:action="$router.push({ name: 'signatories.external.create' })"/>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiHomeCard icon="flat"
                       :title="counters.users"
                       :subtitle="$t('dashboard.counters.users.title')"
                       :button="$t('button.create')"
                       color="orange"
                       @click:homeCard="$router.push({ name: 'settings.users.index' })"
                       @click:action="$router.push({ name: 'settings.users.create' })"/>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiHomeCard icon="file"
                       :title="counters.templates"
                       :subtitle="$t('dashboard.counters.templates.title')"
                       :button="$t('button.list')"
                       color="blue"
                       @click:homeCard="$router.push({ name: 'templates.all' })"
                       @click:action="$router.push({ name: 'templates.all' })"/>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiGrid gutters class="eui-u-p-0">
            <EuiGridRow>
              <EuiGridColumn class="eui-u-pl-0">
                <section class="es-card -white -px-6-py-8 eui-u-cursor-pointer"
                         @click="$router.push({ name: 'signworkflows', params: { tab: 'waiting-for-action' } })">
                    <div class="es-card__numbers -large -center">
                      <p class="es-card__title -blue">
                        <template v-if="this.usageDatas">
                          {{ this.usageDatas.nbWaitingForActions }}
                        </template>
                        <template v-else>
                          --
                        </template>
                      </p>
                      <p class="es-card__category -grey">{{ $t('signworkflows.status.to-validate') }}</p>
                    </div>
                </section>
              </EuiGridColumn>
              <EuiGridColumn class="eui-u-pr-0">
                <section class="es-card -white -px-6-py-8 eui-u-cursor-pointer"
                         @click="$router.push({ name: 'signworkflows', params: { tab: 'waiting-for-others' } })">
                  <div class="es-card__numbers -large -center">
                    <p class="es-card__title -pink">
                      <template v-if="this.usageDatas">
                        {{ this.usageDatas.nbWaitingForOthers }}
                      </template>
                      <template v-else>
                        --
                      </template>
                    </p>
                    <p class="es-card__category -grey">{{ $t('signworkflows.status.waiting-for-others') }}</p>
                  </div>
                </section>
              </EuiGridColumn>
            </EuiGridRow>
            <EuiGridRow>
              <EuiGridColumn class="eui-u-pl-0 eui-u-pb-0">
                <section class="es-card -white -px-6-py-8 eui-u-cursor-pointer"
                         @click="$router.push({ name: 'signworkflows', params: { tab: 'expire-soon' } })">
                  <div class="es-card__numbers -large -center">
                    <p class="es-card__title -pink">
                      <template v-if="this.usageDatas">
                        {{ this.usageDatas.nbExpireSoon }}
                      </template>
                      <template v-else>
                        --
                      </template>
                    </p>
                    <p class="es-card__category -grey">{{ $t('signworkflows.status.expire-soon') }}</p>
                  </div>
                </section>
              </EuiGridColumn>
              <EuiGridColumn class="eui-u-pr-0 eui-u-pb-0">
                <section class="es-card -white -px-6-py-8 eui-u-cursor-pointer"
                         @click="$router.push({ name: 'signworkflows', params: { tab: 'completed' } })">
                  <div class="es-card__numbers -large -center">
                    <p class="es-card__title -blue">
                      <template v-if="this.usageDatas">
                        {{ this.usageDatas.nbCompletedTotal }}
                      </template>
                      <template v-else>
                        --
                      </template>
                    </p>
                    <p class="es-card__category -grey">{{ $t('signworkflows.status.completed') }}</p>
                  </div>
                </section>
              </EuiGridColumn>
            </EuiGridRow>
          </EuiGrid>
        </EuiGridColumn>
        <EuiGridColumn>
          <EuiBlock :title="$t('dashboard.templates.title')" class="es-templates-block">
            <EuiList v-if="pinedToHomePage.length">
              <EuiListItem v-for="template in pinedToHomePage"
                           :key="template.templateId"
                           class="eui-u-bb-px">
                <template v-slot:graphic>
                  <EuiIcon name="file"/>
                </template>
                {{ template.name }}
                <template v-slot:meta>
                  <EuiButton variant="text" color="primary" class="eui-u-px-2"
                             @click="$router.push({ name: 'templates.use.addParameters', params: { templateId: template.id } })">
                    {{ $t('button.use')}}
                  </EuiButton>
                </template>
              </EuiListItem>
            </EuiList>
            <EuiHeading :level="4" v-else>{{ $t('dashboard.templates.none') }}</EuiHeading>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('dashboard.month-stats.title')" style="flex-grow: 0">
            <div class="eui-u-flex" :style="{ visibility: loadingStats ? 'hidden' : 'visible' }">
              <div class="es-dashboard__month-stat ">
                <div class="es-dashboard__month-stat__circle">
                  <div class="es-dashboard__month-stat__circle__highlight-left"></div>
                  <div class="es-dashboard__month-stat__circle__highlight-right"></div>
                </div>
                <p class="es-dashboard__month-stat__subtitle">{{ monthStat.completed }}/{{monthStat.total}}</p>
              </div>
              <div class="eui-u-flex eui-u-content-center">
                <EuiHeading :level="2">{{ $tc('dashboard.month-stats.label', this.monthStat.completed) }}</EuiHeading>
              </div>
            </div>
          </EuiBlock>
        </EuiGridColumn>
        <EuiGridColumn>
          <img src="/help.svg" alt="help" style="width: 100%; height: auto; border-radius: 5px 5px 0 0">
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import TemplateApi from '@/api/template';
import SignatoryApi from '@/api/signatory';
import UserApi from '@/api/user';
import ParapherApi from '@/api/signworkflows';

export default {
  data() {
    return {
      templates: undefined,
      loadingStats: true,
      counters: {
        paraphers: '--',
        signatories: '--',
        users: '--',
        templates: '--',
      },
      monthStat: {
        completed: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      pinedToHomePage: (state) => state.ModuleEdocSign.preferences.pinedToHomePage,
    }),
    ...mapGetters({
      usageDatas: 'ModuleEdocSign/auth/usageDatas',
      user: 'ModuleEdocSign/auth/user',
    }),
  },
  watch: {
    usageDatas(value) {
      if (value !== undefined) {
        this.setUsageDatas();
      }
    },
  },
  methods: {
    ...mapActions({
      storeGetPinToHomePage: 'ModuleEdocSign/preferences/getPinToHomePage',
    }),
    goToHelp() {
      window.open(this.$t('button.help.link'), '_blank').focus();
    },
    updateMonthStats() {
      let left = document.getElementsByClassName('es-dashboard__month-stat__circle__highlight-left')[0];
      let right = document.getElementsByClassName('es-dashboard__month-stat__circle__highlight-right')[0];

      if (this.monthStat.total === 0 || this.monthStat.completed === 0) {
        right.style.height = '0';
        left.style.height = '-180px';
        left.style.top = '180px';
      } else {
        let value = this.monthStat.completed / this.monthStat.total * 360;
        right.style.height = `${value}px`;
        left.style.height = `${value - 180}px`;
        left.style.top = `${180 - (value - 180)}px`;
      }
    },
    setUsageDatas() {
      if (this.usageDatas !== undefined && this.usageDatas !== null) {
        this.monthStat.completed = parseInt(this.usageDatas.nbCompletedInMonth);
        this.monthStat.total = parseInt(this.usageDatas.nbCreatedInMonth);
        if (this.monthStat.total < this.monthStat.completed) {
          this.monthStat.total = this.monthStat.completed;
        }
        this.updateMonthStats();
        this.loadingStats = false;
      }
    },
  },
  mounted() {
    TemplateApi.search({ offset: 0, limit: 5, params: [], orders: [] }).then((response) => {
      this.templates = response.data.models;
      this.counters.templates = response.data.nbModels;
    });
    ParapherApi.getSignworkflows({ offset: 0, limit: 1, params: [], orders: [] })
      .then((response) => { this.counters.paraphers = response.data.nbSignbooks; });
    SignatoryApi.getSignatories({ offset: 0, limit: 1, params: [], orders: [] })
      .then((response) => { this.counters.signatories = response.data.nbAccounts; });
    UserApi.search({ offset: 0, limit: 1, params: [], orders: [] })
      .then((response) => { this.counters.users = response.data.nbAccounts; });
    this.setUsageDatas();
    this.storeGetPinToHomePage();
  },
};
</script>
